<template>
	<div v-if="info" class="pd-b-20">
		<div class="cl">
			<el-tabs v-model="activeName" class="z" @tab-click="onClick">
				<el-tab-pane label="事项详情" name="info"></el-tab-pane>
				<el-tab-pane label="办理情况" name="blqk"></el-tab-pane>
				<el-tab-pane label="里程碑" name="history"></el-tab-pane>
				<el-tab-pane label="点评记录" name="reply"></el-tab-pane>
				<el-tab-pane label="督查记录" name="ducha"></el-tab-pane>
				<el-tab-pane label="领办记录" name="lingqu"></el-tab-pane>
			</el-tabs>
			<div class="y" v-if="info">
<!--				<base-button :permission="info.permission && info.permission.length > 0 ? info.permission : null" :role="'program:all:shenpi'" :disabled="info.state !== 1" size="small" type="primary" @click.native="show_shenpi = true" icon="el-icon-circle-check">审批</base-button>-->
<!--				<base-button :permission="info.permission && info.permission.length > 0 ? info.permission : null" :role="'program:all:reshenpi'" :disabled="[2,3,11].indexOf(info.state) < 0" size="small" type="primary" @click.native="toReShenpi" icon="el-icon-refresh" class="purple">反审批</base-button>-->
<!--				<base-button :permission="info.permission && info.permission.length > 0 ? info.permission : null" :role="'program:all:pick'" :disabled="[2,3].indexOf(info.state) < 0" size="small" type="success" @click.native="show_lingqu = true" icon="el-icon-upload2">领取</base-button>-->
<!--				<base-button :permission="info.permission && info.permission.length > 0 ? info.permission : null" :role="'program:all:todo'" :disabled="[4,5,6,7].indexOf(info.state) < 0" size="small" type="warning" @click.native="toCheckCanBanli" icon="el-icon-edit-outline">汇报</base-button>-->
<!--				<base-button :permission="info.permission && info.permission.length > 0 ? info.permission : null" :role="'program:all:cuiban'" :disabled="[4,5,6,7].indexOf(info.state) < 0" size="small" type="danger" @click.native="toCuiban" icon="el-icon-message-solid">催办</base-button>-->
<!--				<base-button :permission="info.permission && info.permission.length > 0 ? info.permission : null" :role="'program:all:cuiling'" :disabled="[2,3].indexOf(info.state) < 0" size="small" type="danger" @click.native="toCuiling" icon="el-icon-bell">催领</base-button>-->
<!--				<base-button :permission="info.permission && info.permission.length > 0 ? info.permission : null" :role="'program:all:delay'" :disabled="[2,3,4,5,6,7].indexOf(info.state) < 0" size="small" type="info" @click.native="show_delay = true" icon="el-icon-d-arrow-right">延期</base-button>-->
<!--				<base-button :permission="info.permission && info.permission.length > 0 ? info.permission : null" :role="'program:all:stop'" :disabled="[2,3,4,5,6,7].indexOf(info.state) < 0" size="small" type="" @click.native="show_stop = true" icon="el-icon-video-pause">终止</base-button>-->
				<base-button :permission="info.permission && info.permission.length > 0 ? info.permission : null" :role="'program:'+ptype+':shenpi'" :disabled="[1].indexOf(info.state) < 0" size="small" type="primary" @click.native="show_shenpi = true" icon="el-icon-circle-check">审批</base-button>
				<base-button :permission="info.permission && info.permission.length > 0 ? info.permission : null" :role="'program:'+ptype+':reshenpi'" :disabled="[2,6].indexOf(info.state) < 0" size="small" type="primary" @click.native="toReShenpi" icon="el-icon-refresh" class="purple">反审批</base-button>
				<base-button :permission="info.permission && info.permission.length > 0 ? info.permission : null" :role="'program:'+ptype+':pick'" :disabled="[2].indexOf(info.state) < 0" size="small" type="success" @click.native="show_lingqu = true" icon="el-icon-upload2" v-if="info.can_pick === 1">领取</base-button>
				<base-button :permission="info.permission && info.permission.length > 0 ? info.permission : null" :role="'program:'+ptype+':todo'" :disabled="[3].indexOf(info.state) < 0" size="small" type="warning" @click.native="toCheckCanBanli" icon="el-icon-edit-outline" v-if="info.can_huibao === 1">汇报</base-button>
				<base-button :permission="info.permission && info.permission.length > 0 ? info.permission : null" :role="'program:'+ptype+':cuiban'" :disabled="[3].indexOf(info.state) < 0" size="small" type="danger" @click.native="toCuiban" icon="el-icon-message-solid">催办</base-button>
				<base-button :permission="info.permission && info.permission.length > 0 ? info.permission : null" :role="'program:'+ptype+':cuiling'" :disabled="[2].indexOf(info.state) < 0" size="small" type="danger" @click.native="toCuiling" icon="el-icon-bell">催领</base-button>
				<base-button :permission="info.permission && info.permission.length > 0 ? info.permission : null" :role="'program:'+ptype+':delay'" :disabled="[2,3].indexOf(info.state) < 0" size="small" type="info" @click.native="show_delay = true" icon="el-icon-d-arrow-right">延期</base-button>
				<base-button :permission="info.permission && info.permission.length > 0 ? info.permission : null" :role="'program:'+ptype+':stop'" :disabled="[2,3].indexOf(info.state) < 0" size="small" type="" @click.native="show_stop = true" icon="el-icon-video-pause">终止</base-button>
				<base-button :permission="info.permission && info.permission.length > 0 ? info.permission : null" :role="'program:'+ptype+':absstate'" size="small" type="danger" @click.native="show_absstate = true" icon="el-icon-video-pause">修改状态</base-button>
			</div>
		</div>
		<div class="mg-b-10 f-14 f-n" v-if="info.is_around === 1 || info.left_days >= 0">
			<div class="mg-r-30 inline-block" v-if="info.is_around === 1">
				<el-tag type="danger" size="mini">周期性汇报</el-tag>
				{{info.around_str}}
			</div>
			<div class="inline-block dark-4">
				<template v-if="info.is_long === 1">长期</template>
				<template v-else>
					<template v-if="info.left_days !== '' && info.left_days >= 0">
						距离事项期限还有 <b class="blue-0">{{info.left_days}}</b> 天
					</template>
				</template>
			</div>
		</div>
		<div class="mg-t-b-10">
<!--			<el-tag type="" v-if="info.state === 0">已失效</el-tag>-->
<!--			<el-tag type="primary" size="small" v-if="info.state === 1">待审批</el-tag>-->
<!--			<el-tag type="primary" size="small" effect="dark" v-if="info.state === 2">待领办</el-tag>-->
<!--			<el-tag type="warning" size="small" effect="dark" v-if="info.state === 3">办理中</el-tag>-->
<!--			<el-tag type="success" size="small" v-if="info.state === 4">已办结</el-tag>-->
<!--			<el-tag type="info" size="small" v-if="info.state === 5">已终止</el-tag>-->
<!--			<el-tag type="info" size="small" effect="dark" v-if="info.state === 6">已拒绝发布</el-tag>-->
			<el-tag type="" v-if="info.state === 0">{{info.state_str}}</el-tag>
			<el-tag type="primary"  v-if="info.state === 1">{{info.state_str}}</el-tag>
			<el-tag type="primary"  effect="dark" v-if="info.state === 2">{{info.state_str}}</el-tag>
			<!--		                <el-tag type="warning"  v-if="info.state === 3">待领办(已逾期)</el-tag>-->
			<el-tag type="warning"  effect="dark" v-if="info.state === 3 && info.pstate === 0">{{info.state_str}}</el-tag>
			<el-tag type="danger"  effect="dark" v-if="info.state === 3 && info.pstate === 2">{{info.state_str}}</el-tag>
			<el-tag type="warning"  v-if="info.state === 3 && info.pstate === 7">{{info.state_str}}</el-tag>
			<el-tag type="success"  v-if="info.state === 4">{{info.state_str}}</el-tag>
			<el-tag type="info"  v-if="info.state === 5">{{info.state_str}}</el-tag>
			<el-tag type="info"  effect="dark" v-if="info.state === 6">{{info.state_str}}</el-tag>

			<!--					<el-tag type="" v-if="info.state === 0">已失效</el-tag>-->
			<!--					<el-tag type="primary" v-if="info.state === 1">待审批</el-tag>-->
			<!--					<el-tag type="primary" v-if="info.state === 2">待领办</el-tag>-->
			<!--					<el-tag type="warning" v-if="info.state === 3">待领办(已逾期)</el-tag>-->
			<!--					<el-tag type="primary" v-if="info.state === 4">办理中</el-tag>-->
			<!--					<el-tag type="danger" v-if="info.state === 5">退回办理</el-tag>-->
			<!--					<el-tag type="danger" v-if="info.state === 6">延期办理</el-tag>-->
			<!--					<el-tag type="danger" v-if="info.state === 7">异议事项</el-tag>-->
			<!--					<el-tag type="danger" v-if="info.state === 8">逾期办结</el-tag>-->
			<!--					<el-tag type="success" v-if="info.state === 9">已办结</el-tag>-->
			<!--					<el-tag type="info" v-if="info.state === 10">已终止</el-tag>-->
			<!--					<el-tag type="info" v-if="info.state === 11">已拒绝发布</el-tag>-->
			<span class="mg-l-10">{{info.title}}</span>
		</div>
		<template v-if="activeName === 'info'">
			<div class="f-16 f-b mg-t-15" v-if="info.html_content">事项详述</div>
			<div class="f-16 dark-3 mg-t-5 pd-10" style="white-space: pre-wrap;border:1px solid #eee;" v-if="info.html_content">{{info.html_content}}</div>
			<el-descriptions class="mg-t-10" title="事项基本信息" :column="2" size="normal" border v-if="info">
<!--				<template slot="extra">-->
<!--					<el-button type="primary" size="small">操作</el-button>-->
<!--				</template>-->
				<el-descriptions-item label="来源会议" v-if="info.special && info.special.title && info.type === 'hysx'">{{info.special.title}}</el-descriptions-item>
				<el-descriptions-item label="交办时间">{{info.start_time}}</el-descriptions-item>
				<el-descriptions-item label="完成时限">{{info.is_long === 1 ? '长期' : info.end_time}}</el-descriptions-item>
				<el-descriptions-item label="事项分类">{{info.typeStr}}</el-descriptions-item>
				<el-descriptions-item label="事项类型">{{info.program_typeStr}}</el-descriptions-item>
				<el-descriptions-item label="交办单位">{{info.dept.name}}</el-descriptions-item>
<!--				<el-descriptions-item label="牵头单位">{{info.qtdwsStr}}</el-descriptions-item>-->
				<el-descriptions-item label="发布人">{{info.user.name}}</el-descriptions-item>
<!--				<el-descriptions-item label="责任单位">{{info.zrdwsStr}}</el-descriptions-item>-->
				<el-descriptions-item label="督办员">{{info.dbysStr}}</el-descriptions-item>
			</el-descriptions>
<!--			<div style="width: 400px">-->
			<zhb-table title="牵头单位" class="mg-t-20" v-if="info">
				<el-table :data="info.qtdw" border header-row-class-name="tb-header">
					<el-table-column prop="dept_name" label="单位"></el-table-column>
					<el-table-column prop="lingdao_name" label="领导"></el-table-column>
					<el-table-column prop="bsy_name" label="联络员"></el-table-column>
					<el-table-column prop="pick_state" label="领办状态">
						<template slot-scope="scope">
							<el-tag type="danger" v-if="scope.row.pick_state === 0">待领办</el-tag>
							<el-tag type="success" v-if="scope.row.pick_state === 1">已领办</el-tag>
						</template>
					</el-table-column>
				</el-table>
			</zhb-table>
			<zhb-table title="责任单位" class="mg-t-20" v-if="info">
				<el-table :data="info.zrdw" border header-row-class-name="tb-header">
					<el-table-column prop="dept_name" label="单位"></el-table-column>
					<el-table-column prop="lingdao_name" label="领导"></el-table-column>
					<el-table-column prop="bsy_name" label="联络员"></el-table-column>
					<el-table-column prop="pick_state" label="领办状态">
						<template slot-scope="scope">
							<el-tag type="danger" v-if="scope.row.pick_state === 0">待领办</el-tag>
							<el-tag type="success" v-if="scope.row.pick_state === 1">已领办</el-tag>
						</template>
					</el-table-column>
				</el-table>
			</zhb-table>
<!--				<el-descriptions class="mg-t-20" title="责任单位" :column="3" size="normal" border v-if="info">-->
<!--					<template v-for="(item,index) in info.zrdw">-->
<!--						<el-descriptions-item label="单位">{{item.dept_name}}</el-descriptions-item>-->
<!--						<el-descriptions-item label="领导">{{item.lingdao_name}}</el-descriptions-item>-->
<!--						<el-descriptions-item label="办事员">{{item.bsy_name}}</el-descriptions-item>-->
<!--					</template>-->
<!--				</el-descriptions>-->
<!--			</div>-->
			<zhb-table title="相关文件" class="mg-t-20" v-if="info">
				<zhb-tr>
					<zhb-th style="width: 100px;">相关图片</zhb-th>
					<zhb-td><image-pre-view :value="info.images" custom-class="mg-r-10" width="100px"></image-pre-view></zhb-td>
				</zhb-tr>
				<zhb-tr>
					<zhb-th style="width: 100px;">相关附件</zhb-th>
					<zhb-td>
						<a :href="$utils.formatUrl(item.url)" v-for="(item,index) in info.files" class="light bg-blue-0 pd-t-b-3 f-14 inline-block bd-radius-4 pd-l-r-15" target="_blank"><i class="el-icon-paperclip f-16 vm mg-r-5"></i>{{item.name}}</a>
					</zhb-td>
				</zhb-tr>
				<zhb-tr>
					<zhb-th style="width: 100px;">相关视频</zhb-th>
					<zhb-td>
						<zhb-video v-for="(item,index) in info.videos" :value="item.url"></zhb-video>
					</zhb-td>
				</zhb-tr>
			</zhb-table>
		</template>
		<template v-else-if="activeName === 'history'">
			<div class="mg-auto mg-t-40" style="width: 700px">
				<el-timeline>
					<el-timeline-item :type="types[index % 5]" :timestamp="item.create_time" placement="top" v-for="(item,index) in timeLineList">
						<div class="hide bd-radius-6" style="box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);">
							<div class="f-b pd-10 diy-header">{{item.user.name}} {{item.des}}</div>
							<div class="pd-10" v-if="item.pdes || item.ext_val || item.remark">
								<div class="blue-0" v-if="item.pdes || item.ext_val">
									<span class="mg-r-20 f-b f-16">{{item.pdes}}</span>{{item.ext_val}}
								</div>
								<div class="dark-5 mg-t-5" v-if="item.remark">备注：{{item.remark}}</div>
							</div>
						</div>
					</el-timeline-item>
				</el-timeline>
			</div>
		</template>
		<template v-if="activeName === 'blqk'">
			<el-table :data="banliList" border>
				<el-table-column type="index" label="序号" width="50"></el-table-column>
				<el-table-column label="办理人" width="80">
					<template slot-scope="scope">
						{{scope.row.user.name}}
					</template>
				</el-table-column>
				<el-table-column prop="remark" label="情况说明" width="350"></el-table-column>
				<el-table-column prop="state" label="办理状态">
					<template slot-scope="scope">
						<el-tag v-if="scope.row.state === 0">待汇报</el-tag>
						<el-tag type="success" v-if="scope.row.state === 1">已办结</el-tag>
						<el-tag type="warning" v-if="scope.row.state === 2">正常推进中</el-tag>
					</template>
				</el-table-column>
				<el-table-column label="相关图片">
					<template slot-scope="scope">
						<image-pre-view :value="scope.row.images" custom-class="mg-r-10" width="50px"></image-pre-view>
					</template>
				</el-table-column>
				<el-table-column label="相关文件">
					<template slot-scope="scope">
						<a :href="$utils.formatUrl(item.url)" v-for="(item,index) in scope.row.files" class="f-14 block" target="_blank"><i class="el-icon-paperclip f-16 vm mg-r-5"></i>{{item.name}}</a>
<!--						<a @click="toDownLoad(item)" v-for="(item,index) in scope.row.files" class="f-14 block" target="_blank"><i class="el-icon-paperclip f-16 vm mg-r-5"></i>{{item.name}}</a>-->
					</template>
				</el-table-column>
				<el-table-column label="相关视频">
					<template slot-scope="scope">
						<zhb-video v-for="(item,index) in scope.row.videos" :value="item.url" width="100px" height="50px"></zhb-video>
					</template>
				</el-table-column>
				<el-table-column prop="state" label="领导审批">
					<template slot-scope="scope">
						<template v-if="scope.row.state === 0">
							-
						</template>
						<template v-else>
							<el-tag type="success" v-if="scope.row.shenpi_state === 1">通过</el-tag>
							<el-tag type="danger" v-if="scope.row.shenpi_state === 2">驳回</el-tag>
							<el-tag type="warning" v-if="scope.row.shenpi_state === 0">待审批</el-tag>
							<div class="mg-t-5" v-if="scope.row.shenpi_remark">{{scope.row.shenpi_remark}}</div>
						</template>
					</template>
				</el-table-column>
<!--				<el-table-column prop="state" label="督查情况" show-overflow-tooltip>-->
<!--					<template slot-scope="scope">-->
<!--						<el-tag v-if="scope.row.check_state === 9">已办结</el-tag>-->
<!--						<el-tag v-if="scope.row.check_state === 5">退回办理</el-tag>-->
<!--						<el-tag v-if="scope.row.check_state === 7">异议事项</el-tag>-->
<!--					</template>-->
<!--				</el-table-column>-->
				<el-table-column prop="state" label="督查情况" width="100">
					<template slot-scope="scope">
						<template v-if="scope.row.state === 0">
							-
						</template>
						<template v-else>
							<el-tag type="success" v-if="scope.row.ducha_state === 1">通过</el-tag>
							<el-tag type="danger" v-if="scope.row.ducha_state === 2">驳回</el-tag>
							<el-tag type="warning" v-if="scope.row.ducha_state === 0">待审核</el-tag>
							<div class="mg-t-5" v-if="scope.row.ducha_remark">{{scope.row.ducha_remark}}</div>
						</template>
					</template>
				</el-table-column>
<!--				<el-table-column prop="create_time" label="周期时间" show-overflow-tooltip v-if="info.is_around === 1" width="200">-->
<!--					<template slot-scope="scope">-->
<!--						{{scope.row.start_time}} - {{scope.row.end_time}}-->
<!--					</template>-->
<!--				</el-table-column>-->
				<el-table-column prop="create_time" label="汇报时间" width="170"></el-table-column>
				<el-table-column prop="is_delay" label="是否延迟" width="100" v-if="info.is_around === 1">
					<template slot-scope="scope">
						<span v-if="scope.row.is_delay === 1">延迟</span>
						<span v-if="scope.row.is_delay === 0">正常</span>
					</template>
				</el-table-column>
				<el-table-column label="操作" width="150">
					<template slot-scope="scope">
<!--						<template v-if="scope.row.is_around === 1">-->
<!--							<template v-if="$moment().unix() >= $moment(scope.row.start_time).unix() || $moment().unix() <= $moment(scope.row.end_time).unix()">-->
<!--								<base-button :permission="info.permission && info.permission.length > 0 ? info.permission : null" :role="'program:'+ptype+':todo'" type="text" icon="el-icon-edit-outline" @click.native="toBanli(scope.row)" v-if="scope.row.state === 0 && info.state === 3">汇报</base-button>-->
<!--								<base-button :permission="info.permission && info.permission.length > 0 ? info.permission : null" :role="'program:'+ptype+':todo'" type="text" icon="el-icon-edit-outline" @click.native="toBanli(scope.row)" v-if="scope.row.state > 0 && (scope.row.shenpi_state === 2 || scope.row.ducha_state === 2)">重新汇报</base-button>-->
<!--								<base-button :permission="info.permission && info.permission.length > 0 ? info.permission : null" :role="'program:'+ptype+':todo'" type="text" icon="el-icon-refresh-left" @click.native="toRefundTodo(scope.row)" v-if="scope.row.state > 0 && scope.row.shenpi_state === 0 && scope.row.ducha_state === 0">撤回</base-button>-->
<!--								<base-button :permission="info.permission && info.permission.length > 0 ? info.permission : null" :role="'program:'+ptype+':todoshenpi'" icon="el-icon-folder-checked"  @click.native="toDoShenpi(scope.row)" v-if="scope.row.shenpi_state === 0 && scope.row.state > 0">审批</base-button>-->
<!--								<base-button :permission="info.permission && info.permission.length > 0 ? info.permission : null" :role="'program:'+ptype+':ducha'" type="text" icon="el-icon-search" @click.native="toDucha(scope.row)" v-if="scope.row.shenpi_state === 1 && scope.row.state > 0 && scope.row.ducha_state === 0">督查</base-button>-->
<!--								<base-button :permission="info.permission && info.permission.length > 0 ? info.permission : null" :role="'program:'+ptype+':comment'" icon="el-icon-chat-line-square" @click.native="toReply(scope.row,'huibao',info.id)" v-if="scope.row.state > 0 && scope.row.shenpi_state === 1">点评</base-button>-->
<!--								<base-button :role="'program:'+ptype+':refunddo'" icon="el-icon-refresh-left" @click.native="toRefundDo(scope.row)" v-if="scope.$index === 0 && info.state === 4">回退此步</base-button>-->
<!--							</template>-->
<!--							<template v-else>当前不在汇报周期</template>-->
<!--						</template>-->
<!--						<template v-if="scope.row.is_around === 0">-->
							<base-button :permission="info.permission && info.permission.length > 0 ? info.permission : null" :role="'program:'+ptype+':todo'" type="text" icon="el-icon-edit-outline" @click.native="toBanli(scope.row)" v-if="scope.row.state > 0 && (scope.row.shenpi_state === 2 || scope.row.ducha_state === 2) && scope.row.can_huibao === 1">重新汇报</base-button>
						<base-button :permission="info.permission && info.permission.length > 0 ? info.permission : null" :role="'program:'+ptype+':todo'" type="text" icon="el-icon-edit-outline" @click.native="toBanli(scope.row)" v-if="scope.row.state === 0 && scope.row.can_huibao === 1">重新汇报</base-button>
							<base-button :permission="info.permission && info.permission.length > 0 ? info.permission : null" :role="'program:'+ptype+':todo'" type="text" icon="el-icon-refresh-left" @click.native="toRefundTodo(scope.row)" v-if="scope.row.state > 0 && scope.row.shenpi_state === 0 && scope.row.ducha_state === 0 && scope.row.can_huibao === 1">撤回</base-button>
							<base-button :permission="info.permission && info.permission.length > 0 ? info.permission : null" :role="'program:'+ptype+':todoshenpi'" icon="el-icon-folder-checked"  @click.native="toDoShenpi(scope.row)" v-if="scope.row.shenpi_state === 0 && scope.row.state > 0 && scope.row.can_shenpi === 1">审批</base-button>
<!--							<base-button :permission="info.permission && info.permission.length > 0 ? info.permission : null" :role="'program:'+ptype+':todoshenpi'" icon="el-icon-folder-checked"  @click.native="toDoShenpi(scope.row)" >审批</base-button>-->
							<base-button :permission="info.permission && info.permission.length > 0 ? info.permission : null" :role="'program:'+ptype+':ducha'" type="text" icon="el-icon-search" @click.native="toDucha(scope.row)" v-if="scope.row.shenpi_state === 1 && scope.row.state > 0 && scope.row.ducha_state === 0 && scope.row.can_ducha === 1">督查</base-button>
							<base-button :permission="info.permission && info.permission.length > 0 ? info.permission : null" :role="'program:'+ptype+':comment'" icon="el-icon-chat-line-square" @click.native="toReply(scope.row,'huibao',info.id)" v-if="scope.row.state > 0 && scope.row.shenpi_state === 1">点评</base-button>
							<base-button :role="'program:'+ptype+':refunddo'" icon="el-icon-refresh-left" @click.native="toRefundDo(scope.row)" v-if="scope.$index === 0 && info.state === 4">回退此步</base-button>
<!--						</template>-->
					</template>
				</el-table-column>
			</el-table>
		</template>
		<template v-if="activeName === 'ducha'">
			<el-table :data="duchaList" border>
				<el-table-column type="index" label="序号" width="50"></el-table-column>
				<el-table-column prop="remark" label="情况说明" show-overflow-tooltip></el-table-column>
				<el-table-column label="督办人" width="150" show-overflow-tooltip>
					<template slot-scope="scope">
						{{scope.row.user.name}}
					</template>
				</el-table-column>
				<el-table-column prop="pdes" label="督查情况" width="150" show-overflow-tooltip>
					<template slot-scope="scope">
						{{scope.row.pdes}} {{scope.row.ext_val}}
					</template>
				</el-table-column>
<!--				<el-table-column label="督查图片">-->
<!--					<template slot-scope="scope">-->
<!--						<image-pre-view :value="scope.row.images" custom-class="mg-r-10" width="50px"></image-pre-view>-->
<!--					</template>-->
<!--				</el-table-column>-->
<!--				<el-table-column label="督查文件" show-overflow-tooltip>-->
<!--					<template slot-scope="scope">-->
<!--						<a :href="$utils.formatUrl(item.url)" v-for="(item,index) in scope.row.files" class="f-14 block" target="_blank"><i class="el-icon-paperclip f-16 vm mg-r-5"></i>{{item.name}}</a>-->
<!--					</template>-->
<!--				</el-table-column>-->
<!--				<el-table-column label="督查视频">-->
<!--					<template slot-scope="scope">-->
<!--						<zhb-video v-for="(item,index) in scope.row.videos" :value="item.url" width="100px" height="50px"></zhb-video>-->
<!--					</template>-->
<!--				</el-table-column>-->
				<el-table-column prop="create_time" label="督查提交时间" width="170" show-overflow-tooltip></el-table-column>
				<el-table-column label="" width="150">
					<template slot-scope="scope">
						<base-button :permission="info.permission && info.permission.length > 0 ? info.permission : null" :role="'program:'+ptype+':comment'" @click.native="toReply(scope.row,'ducha',info.id)">点评</base-button>
					</template>
				</el-table-column>
			</el-table>
		</template>
		<template v-if="activeName === 'lingqu'">
			<el-table :data="pickList" border>
				<el-table-column type="index" label="序号" width="50"></el-table-column>
				<el-table-column prop="remark" label="备注" show-overflow-tooltip></el-table-column>
				<el-table-column label="领取人" show-overflow-tooltip>
					<template slot-scope="scope">
						{{scope.row.user.name}}
					</template>
				</el-table-column>
				<el-table-column prop="create_time" label="领取时间" show-overflow-tooltip></el-table-column>
			</el-table>
		</template>
		<template v-if="activeName === 'reply'">
			<template v-if="replyList && replyList.length > 0">
				<el-card v-for="(item,index) in replyList" class="mg-b-10">
					<img class="bd-radius hide vm mg-r-10" :src="$utils.formatUrl(item.avatar)" style="height: 40px;width:40px;"/>
					<div class="inline-block vm">
						<div>{{item.name}}</div>
						<div class="f-12 dark-6">{{item.create_time}}</div>
					</div>
					<div class="mg-l-50">
						<div class="pd-10 mg-t-10 text-line-1" style="background-color: #f4f4f4;border-left: 3px solid #007dff" v-if="item.slug">
							<template v-if="item.type === 'huibao'">
								<b class="f-n dark-6">{{item.slug.user.name}} 汇报了工作</b> <b class="f-n blue-0">{{item.slug.remark}}</b>
							</template>
							<template v-else>
								<b class="f-n dark-6">{{item.slug.user.name}} {{item.slug.des}}</b> <b class="f-n blue-0">{{item.slug.pdes}}</b>
							</template>
						</div>
						<div class="mg-t-10 f-16" style="white-space:pre;" v-html="item.content">{{item.content}}</div>
						<div class="pd-15 bd-radius-6 mg-t-10" style="background-color: #f4f4f4;" v-if="item.reply && item.reply.length > 0">
							<div class="f-14 cl" v-for="(st,si) in item.reply" style="line-height: 40px;">
								<img class="bd-radius hide mg-r-10 inline-block z" :src="$utils.formatUrl(st.avatar)" style="margin-top:11px;height: 18px;width:18px;"/>
								<div class="inline-block">
									<b class="f-n">{{st.name}}:</b> 回复 <b class="blue-0 f-n mg-r-5">@{{st.target_name}}</b>{{st.content}}
								</div>
								<base-button :permission="info.permission && info.permission.length > 0 ? info.permission : null" class="mg-l-10" size="medium" type="text" @click.native="toReply(item.slug,item.type,info.id,item.id,st.uid)" :role="'program:'+ptype+':reply'">回复</base-button>
							</div>
						</div>
						<div class="text-left mg-t-20">
							<base-button :permission="info.permission && info.permission.length > 0 ? info.permission : null" :role="'program:'+ptype+':reply'" size="mini" type="primary" @click.native="toReply(item.slug,item.type,info.id,item.id,item.uid)">回复</base-button>
						</div>
					</div>
				</el-card>
			</template>
			<div class="text-center f-14 dark-6" style="line-height: 60px;" v-else>暂无数据</div>
		</template>
		<template v-if="info">
			<shenpi-dialog :visible.sync="show_shenpi" :id="info.id" @success="onSuccess"></shenpi-dialog>
			<lingqu-dialog :visible.sync="show_lingqu" :id="info.id" @success="onSuccess"></lingqu-dialog>
			<banli-dialog :visible.sync="show_banli" :id="info.id" :info="info" :bid="banliId" @success="onSuccess" @close="onCloseBanli"></banli-dialog>
			<delay-dialog :visible.sync="show_delay" :info="info" :id="info.id" @success="onSuccess"></delay-dialog>
			<stop-dialog :visible.sync="show_stop" :id="info.id" @success="onSuccess"></stop-dialog>
			<reply-dialog :visible.sync="show_reply" :id="reply.id" :pid="replyPid" :target-uid="replyTargetUid" :reply-type="replyType" :reply-extid="replyExtid" @success="onSuccess" v-if="reply"></reply-dialog>
			<ducha-dialog :visible.sync="show_ducha" :id="info.id" :bid="duchaId" v-if="duchaId" @close="onCloseDucha"  @success="onSuccess"></ducha-dialog>
			<todo-shenpi-dialog :visible.sync="show_todoshenpi" :id="info.id" :bid="shenpiId" v-if="shenpiId" @close="onCloseToDoShenpi"  @success="onSuccess"></todo-shenpi-dialog>
			<abs-state-dialog :visible.sync="show_absstate" :id="info.id" @success="onSuccess"></abs-state-dialog>
		</template>
	</div>
</template>

<script>
export default {
	name: "detail",
	data() {
		return {
			activeName:'info',
			info:null,
			reply:null,
			replyType:'',
			replyExtid:'',
			replyPid:'',
			replyTargetUid:'',
			id:'',
			show_shenpi:false,
			show_lingqu:false,
			show_banli:false,
			show_ducha:false,
			show_stop:false,
			show_delay:false,
			show_reply:false,
			show_todoshenpi:false,
			show_absstate:false,
			timeLineList:[],
			banliList:[],
			pickList:[],
			duchaList:[],
			replyList:[],
			replyTotal:0,
			duchaId:'',
			banliId:'',
			shenpiId:'',
			types:['primary','success','warning','danger','info'],
			ptype:'',

			replySearchForm:{
				page:1,
				pageSize:10,
				extid:''
			},
		}
	},
	created() {
		this.id = this.$route.query.id ? this.$route.query.id : '';
		this.replySearchForm.extid = this.$route.query.id ? this.$route.query.id : '';

		this.activeName = this.$route.query.tab ? this.$route.query.tab : 'info';
	},
	mounted() {
		if(this.id){
			this.getDetail();
		}
	},
	methods: {
		toDownLoad(e){
			console.log(e);
			if(!e.url){
				return;
			}

			let _name = '下载文件_'+this.$moment().format('x');
			let _url = this.$utils.formatUrl(e.url);

			if(e.name){
				_name = e.name;
			}
			const a = document.createElement('a');
			a.style.display = 'none';
			a.setAttribute('target', '_blank');
			_name && a.setAttribute('download', _name);
			a.href = _url;
			document.body.appendChild(a);
			a.click();
			document.body.removeChild(a);
		},
		toRefundTodo(e){
			let _this = this;
			this.$confirm('是否撤回此汇报内容', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				_this.$http.request({
					url: '/ProgramDo/toRefund',
					datas: {id: e.id,pid:e.pid},
					success(res) {
						_this.onSuccess();
					}
				})
			}).catch(() => {});
		},
		toReply(e,type,extid,pid ='',target_uid = ''){
			this.reply = e;
			this.replyType = type;
			this.replyExtid = extid;
			this.replyPid = pid;
			this.replyTargetUid = target_uid;
			this.show_reply = true;
		},
		toRefundDo(e){
			let _this = this;
			this.$confirm('回退后，事项状态变更为[办理中]，此条工作汇报的督查状态重置为[待审核]，是否继续？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				_this.$http.request({
					url: '/ProgramDo/refundToTimeDo',
					datas: {id: e.id},
					success(res) {
						_this.onSuccess();
					}
				})
			}).catch(() => {});
		},
		toDucha(e){
			this.duchaId = e.id;
			this.show_ducha = true;
		},
		toDoShenpi(e){
			this.shenpiId = e.id;
			this.show_todoshenpi = true;
		},
		onCloseToDoShenpi(){
			this.shenpiId = '';
		},
		onCloseDucha(){
			this.duchaId = '';
		},
		onCloseBanli(){
			this.banliId = '';
		},
		toBanli(e){
			console.log(e);
			this.banliId = e.id;
			this.show_banli = true;
		},
		toCheckCanBanli(){
			let _this = this;

			// if(this.info.is_around === 1){
			// 	this.$alert('该事项为周期性汇报，请到[办理情况]标签中进行汇报', '提示', {
			// 		confirmButtonText: '知道了',
			// 		callback: action => {
			// 			console.log(action);
			// 			_this.activeName = 'blqk'
			// 		}
			// 	});
			// }else {
				this.$http.request({
					url: '/Program/checkCanBanli',
					datas: {id: this.id},
					success(res) {
						_this.show_banli = true;
					}
				})
			// }
		},
		onClick(){
			if(this.activeName === 'history'){
				this.getHistoryList();
			}else if(this.activeName === 'blqk'){
				this.getBanliList();
			}else if(this.activeName === 'lingqu'){
				this.getLogList('pick');
			}else if(this.activeName === 'ducha'){
				this.getLogList('ducha');
			}else if(this.activeName === 'reply'){
				this.getReplyList();
			}
		},
		onSuccess(){
			this.getDetail();
			this.getBanliList();
			this.getHistoryList();
			this.getLogList('pick');
			this.getReplyList();
		},
		getDetail(){
			let _this = this;
			this.$http.request({
				url:'/Program/detail',
				datas:{id:this.id},
				success(res){
					if(res) {
						_this.info = _this.$utils.deepCopy(res);
						_this.ptype = _this.info.type;
					}else{
						_this.$alert('事项不存在 或 已被删除', '提示', {
							confirmButtonText: '立即返回',
							callback: action => {
								_this.$user.removeTab();

								_this.$router.back();
							}
						});
					}
				},
				fail(err){
					_this.$alert('事项不存在 或 已被删除', '提示', {
						confirmButtonText: '立即返回',
						callback: action => {
							_this.$user.removeTab();

							_this.$router.back();
						}
					});
				}
			})
		},
		getReplyList(){
			let _this = this;
			this.$http.request({
				url:'/ReplyLog/listPage',
				datas:this.replySearchForm,
				success(res){
					_this.replyList = res.list;
					_this.replyTotal = res.totalCount;
				}
			})
		},
		getHistoryList(){
			let _this = this;
			this.$http.request({
				url:'/ProgramLog/listAll',
				datas:{pid:this.id,orderBy:'create_time',sortBy:'asc'},
				success(res){
					_this.timeLineList = res;
				}
			})
		},
		getLogList(type){
			let _this = this;

			if(!type){
				this.timeLineList = [];
			}
			this.$http.request({
				url:'/ProgramLog/listAll',
				datas:{pid:this.id,orderBy:'create_time',sortBy:'desc',logtype:type},
				success(res){
					_this.$set(_this,type+'List',res);
				}
			})
		},
		getBanliList(){
			let _this = this;
			this.$http.request({
				url:'/ProgramDo/listAll',
				datas:{pid:this.id,orderBy:'create_time',sortBy:'desc'},
				success(res){
					_this.banliList = res;
				}
			})
		},
		toReShenpi(){
			let _this = this;
			this.$confirm('是否要重置为待审批状态?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				_this.$http.request({
					url:'/Program/toReShenpi',
					datas:{id:this.id,state:1},
					success(res){
						_this.onSuccess();
						_this.$message.success('操作成功');
					}
				})
			}).catch(() => {});
		},
		toCuiban(){
			let _this = this;
			this.$confirm('是否催办?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				_this.$http.request({
					url:'/Program/toCuiban',
					datas:{id:this.id},
					success(res){
						_this.onSuccess();
						_this.$message.success('催办成功');
					}
				})
			}).catch(() => {});
		},
		toCuiling(){
			let _this = this;
			this.$confirm('是否要催领?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				_this.$http.request({
					url:'/Program/toCuiling',
					datas:{id:this.id},
					success(res){
						_this.onSuccess();
						_this.$message.success('催领成功');
					}
				})
			}).catch(() => {});
		}
	}
}
</script>
